import React from 'react';
import { MessageSquare } from 'lucide-react';
import ToolPageTemplate from './ToolPageTemplate';

const AIChatBot = () => {
  return (
    <ToolPageTemplate
      title="AI Chat Bot"
      description="Enhance your customer service with our intelligent AI-powered chatbot solution that provides 24/7 support and seamless customer interactions."
      icon={<MessageSquare className="w-8 h-8 text-emerald-400" />}
      features={[
        "Natural Language Processing",
        "Multi-language Support",
        "Custom Knowledge Base",
        "Real-time Analytics",
        "Human Handoff",
        "Automated Responses",
        "Contextual Understanding"
      ]}
      benefits={[
        "24/7 Customer Support",
        "Reduced Support Costs",
        "Faster Response Times",
        "Consistent Experience",
        "Scalable Solution",
        "Valuable Insights",
        "Improved Satisfaction"
      ]}
      integrations={[
        "Website Widget",
        "Facebook Messenger",
        "WhatsApp",
        "Slack",
        "Microsoft Teams",
        "Custom API"
      ]}
      metaTitle="AI Chat Bot | NeuroGen Lab - Intelligent Customer Service Solution"
      metaDescription="Transform your customer service with our AI-powered chatbot. Provide 24/7 support, reduce costs, and deliver exceptional customer experiences."
    />
  );
};

export default AIChatBot;