import React from 'react';

interface PreferencesLinkProps {
  className?: string;
}

const PreferencesLink: React.FC<PreferencesLinkProps> = ({ className = '' }) => {
  return (
    <a 
      href="#" 
      className={`termly-display-preferences ${className}`}
      onClick={(e) => {
        e.preventDefault();
        // Termly will handle the click event via the class name
      }}
    >
      Consent Preferences
    </a>
  );
};

export default PreferencesLink;