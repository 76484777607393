import React from 'react';
import { Mic } from 'lucide-react';
import ToolPageTemplate from './ToolPageTemplate';

const AIVoiceBot = () => {
  return (
    <ToolPageTemplate
      title="AI Voice Bot"
      description="Transform your voice interactions with our advanced AI Voice Bot that delivers natural, engaging conversations and intelligent automated responses."
      icon={<Mic className="w-8 h-8 text-emerald-400" />}
      features={[
        "Advanced Speech Recognition",
        "Natural Language Understanding",
        "Voice Biometrics",
        "Multi-accent Support",
        "Real-time Analysis",
        "Dynamic Response Generation",
        "Emotion Detection"
      ]}
      benefits={[
        "Reduced Call Center Costs",
        "24/7 Voice Support",
        "Improved Experience",
        "Faster Resolution",
        "Consistent Quality",
        "Scalable Solution",
        "Valuable Analytics"
      ]}
      integrations={[
        "Phone Systems",
        "VoIP Platforms",
        "Call Center Software",
        "CRM Systems",
        "Analytics Tools",
        "Custom Solutions"
      ]}
      metaTitle="AI Voice Bot | NeuroGen Lab - Intelligent Voice Interaction Solution"
      metaDescription="Enhance your customer interactions with our AI Voice Bot. Provide natural, intelligent voice responses and 24/7 automated support."
    />
  );
};

export default AIVoiceBot;