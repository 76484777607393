import React from 'react';
import { Lock, Shield, Share2, Database, UserCheck } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Divider from '../components/Divider';
import ScrollToTop from '../components/ScrollToTop';
import BackToHome from '../components/BackToHome';

const Privacy = () => {
  const sections = [
    {
      title: "Information Collection",
      icon: <Database className="w-6 h-6" />,
      content: `We collect information that you provide directly to us, including name, email address, and any other information you choose to provide. We also automatically collect certain information about your device when you use our services.`
    },
    {
      title: "Use of Information",
      icon: <Shield className="w-6 h-6" />,
      content: `We use the information we collect to provide, maintain, and improve our services, to develop new services, and to protect NeuroGen Lab and our users. We may also use the information to communicate with you about products, services, and updates.`
    },
    {
      title: "Information Sharing",
      icon: <Share2 className="w-6 h-6" />,
      content: `We do not share your personal information with third parties except as described in this privacy policy. We may share your information with third-party vendors who need access to your information to provide us with services.`
    },
    {
      title: "Data Security",
      icon: <Lock className="w-6 h-6" />,
      content: `We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%.`
    },
    {
      title: "Your Rights",
      icon: <UserCheck className="w-6 h-6" />,
      content: `You have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.`
    }
  ];

  return (
    <div className="min-h-screen bg-[#0B0F19] text-white">
      <Navbar />
      
      <main className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
          <BackToHome />
          
          <div className="text-center mb-12">
            <Lock className="w-12 h-12 text-emerald-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-gray-400">
              Last updated: March 15, 2024
            </p>
          </div>

          <Divider />

          <div className="mt-12 space-y-8">
            {sections.map((section, index) => (
              <div 
                key={index}
                className="bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="text-emerald-400">
                    {section.icon}
                  </div>
                  <h2 className="text-xl font-semibold text-emerald-400">
                    {section.title}
                  </h2>
                </div>
                <div className="text-gray-300 leading-relaxed">
                  {section.content}
                </div>
              </div>
            ))}

            <div className="bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300">
              <h2 className="text-xl font-semibold mb-4 text-emerald-400">
                Contact Us
              </h2>
              <p className="text-gray-300 leading-relaxed">
                For questions about our privacy practices, please contact us at{' '}
                <a 
                  href="mailto:office@neurogenlab.de" 
                  className="text-emerald-400 hover:text-emerald-300 transition-colors"
                >
                  office@neurogenlab.de
                </a>
              </p>
            </div>
          </div>
        </div>
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default Privacy;