import React from 'react';
import { Cookie, Shield, Database, BarChart3, Sparkles } from 'lucide-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Divider from '../components/Divider';
import ScrollToTop from '../components/ScrollToTop';
import BackToHome from '../components/BackToHome';

const CookiePolicy = () => {
  const sections = [
    {
      title: "Essential Cookies (Security Storage)",
      icon: <Shield className="w-6 h-6" />,
      content: [
        "Setting your privacy preferences",
        "Logging in or filling in forms",
        "Basic website functionality",
        "Security features and fraud prevention",
        "These cookies do not store any personally identifiable information"
      ]
    },
    {
      title: "Analytics Cookies",
      icon: <BarChart3 className="w-6 h-6" />,
      content: [
        "Anonymize IP addresses",
        "Respect Do Not Track settings",
        "Store data in the EU region",
        "Comply with GDPR requirements",
        "Count visits and traffic sources",
        "Measure site performance"
      ]
    },
    {
      title: "Advertising Cookies",
      icon: <Sparkles className="w-6 h-6" />,
      content: [
        "Conversion tracking",
        "Remarketing capabilities",
        "Cross-device tracking",
        "Frequency capping",
        "Campaign performance measurement",
        "Interest-based advertising"
      ]
    },
    {
      title: "Personalization & Functionality",
      icon: <Database className="w-6 h-6" />,
      content: [
        "Your preferences and settings",
        "Language preferences",
        "Location-based content",
        "Custom user interface settings",
        "Form pre-fill information",
        "Login state and authentication"
      ]
    }
  ];

  const dataUsage = [
    "IP addresses (anonymized)",
    "Browser type and version",
    "Operating system",
    "Referral source",
    "Length of visit",
    "Pages viewed",
    "Navigation paths",
    "Time and date of visit",
    "Time zone setting",
    "Device information"
  ];

  const rights = [
    "Right to be informed about how we use cookies",
    "Right to withdraw consent at any time",
    "Right to reject non-essential cookies",
    "Right to delete cookies from your browser",
    "Right to access information about cookies we use",
    "Right to receive a copy of your cookie preferences"
  ];

  return (
    <div className="min-h-screen bg-[#0B0F19] text-white">
      <Navbar />
      
      <main className="pt-24 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
          <BackToHome />
          
          <div className="text-center mb-12">
            <Cookie className="w-12 h-12 text-emerald-400 mx-auto mb-4" />
            <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>
            <p className="text-gray-400">
              Last updated: March 15, 2024
            </p>
          </div>

          <Divider />

          {/* Introduction */}
          <div className="mt-12 bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
              Introduction
            </h2>
            <p className="text-gray-300 leading-relaxed">
              This Cookie Policy explains how NeuroGen Lab ("we", "us", "our") uses cookies and similar technologies 
              to recognize you when you visit our website. It explains what these technologies are and why we use 
              them, as well as your rights to control our use of them.
            </p>
          </div>

          {/* Cookie Types */}
          <div className="mt-8 grid md:grid-cols-2 gap-6">
            {sections.map((section, index) => (
              <div 
                key={index}
                className="bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="text-emerald-400">
                    {section.icon}
                  </div>
                  <h2 className="text-lg font-semibold text-emerald-400">
                    {section.title}
                  </h2>
                </div>
                <ul className="space-y-2">
                  {section.content.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-start gap-2">
                      <span className="text-emerald-400 mt-1">•</span>
                      <span className="text-gray-300 text-sm">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Data Collection */}
          <div className="mt-8 bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
              Information We Collect
            </h2>
            <div className="grid sm:grid-cols-2 gap-4">
              {dataUsage.map((item, index) => (
                <div key={index} className="flex items-center gap-3">
                  <span className="text-emerald-400">•</span>
                  <span className="text-gray-300 text-sm">{item}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Your Rights */}
          <div className="mt-8 bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
              Your Rights and Choices
            </h2>
            <div className="grid sm:grid-cols-2 gap-4">
              {rights.map((right, index) => (
                <div key={index} className="flex items-center gap-3">
                  <span className="text-emerald-400">•</span>
                  <span className="text-gray-300 text-sm">{right}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Information */}
          <div className="mt-8 bg-gray-900/50 rounded-xl p-6 hover:bg-gray-900/70 transition-all duration-300">
            <h2 className="text-xl font-semibold mb-4 text-emerald-400">
              Contact Us
            </h2>
            <p className="text-gray-300 text-sm leading-relaxed">
              If you have questions about our cookie policy or practices, please contact our Data Protection Officer at{' '}
              <a 
                href="mailto:office@neurogenlab.de" 
                className="text-emerald-400 hover:text-emerald-300 transition-colors"
              >
                office@neurogenlab.de
              </a>
            </p>
          </div>
        </div>
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default CookiePolicy;