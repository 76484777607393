import React from 'react';
import { MessageSquare } from 'lucide-react';
import ToolPageTemplate from './ToolPageTemplate';

const Discord = () => {
  return (
    <ToolPageTemplate
      title="Discord Integration"
      description="Enhance your community engagement with our powerful Discord integration that brings AI-powered moderation and automated interactions to your server."
      icon={<MessageSquare className="w-8 h-8 text-emerald-400" />}
      features={[
        "Automated Moderation",
        "Custom Bot Development",
        "Role Management",
        "Event Automation",
        "Community Analytics",
        "Interactive Commands",
        "Multi-Server Support"
      ]}
      benefits={[
        "Enhanced Community Management",
        "Reduced Moderation Workload",
        "Improved User Engagement",
        "Automated Workflows",
        "Better Server Organization",
        "Real-time Analytics",
        "Seamless Integration"
      ]}
      integrations={[
        "Discord API",
        "Webhook System",
        "Bot Framework",
        "Analytics Tools",
        "Custom Commands",
        "Role Management"
      ]}
      metaTitle="Discord Integration | NeuroGen Lab - Community Management Solution"
      metaDescription="Transform your Discord community with our intelligent integration. Automate moderation, enhance engagement, and streamline server management with AI-powered tools."
    />
  );
};

export default Discord;